import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import 'jquery';
import {BoService} from '../services/bo.service';
import {TicketingService} from "../services/ticketing.service";

declare var $: any;
@Component({
  selector: 'app-liste-des-participants',
  templateUrl: './liste-des-participants.component.html',
  styleUrls: ['./liste-des-participants.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListeDesParticipantsComponent implements OnInit {

  constructor(private boService: BoService, private ticketingService: TicketingService) {
  }
  ngOnInit() {
    let t = this;
    $(document).ready(function(){
      // --- Initialisation globale : --------------------------------------------
      $('.menubo,.headerbo').css({'display':'block'});
      var darkMode='off';
      if(JSON.parse(localStorage.getItem('infoUser')).darkMode=='on'){darkMode='on'};
      var wiw=$(window).width();
      $('#htitsec').html('Participants');
      $('#htitsec2').html('Liste des particpants');
      $('.menubs').attr('id','');$('.menubs-2').attr('id','actif1');
      $('.secmenu2bs').attr('id','');$('.2s1').attr('id','actif2');
      if(wiw>610){
        $('#htitsec,#htitsec2,#fles').css({'display':'inline'});
      }else{
        $('#htitsec2').css({'display':'inline'});
        $('#fles').css({'display':'none'});
      };
      $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
      $(window).scroll(function(){
        var sw= $(this).scrollTop();
        if(sw>110){
          $('.headerbo').css({'border-color':'#b4aaaa00','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.05)'});
        }else{
          $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
        };
      });
      if(wiw>1320 && wiw<=1600){
        $('.searchPar').attr('placeholder','Rechercher');
      }else if(wiw<=265){
        $('.searchPar').attr('placeholder','Rechercher');
      };
      $('body').css({'overflow':'hidden'});
      $(window).scrollTop(0);
      //--------------------------------------------------------------------------
      // --- Initialisation contenu : --------------------------------------------
      var dataList=JSON.parse(localStorage.getItem('dataList'));
      var idlist=dataList._id;
      var dataListTpl=dataList.tpl;
      var dataKeys;
      var getcust=t.boService.getcust;
      var g0;
      var glist=[];
      var csvOptions = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalseparator: '.',
        doc:'liste des participants',
        showLabels: true,
        showTitle: false,
        title: '',
        useBom: true,
        noDownload: false,
        headers: ["ID Compte", "Date de création", "Nom du particpant", "Adresse e-mail", "Nom du tarif", "Numéro code-barre", "Contrôles"]
      };
      var csvOptions2 = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalseparator: '.',
        doc:'liste des participants 2',
        showLabels: true,
        showTitle: false,
        title: '',
        useBom: true,
        noDownload: false,
        headers: ["Nom", "Prénom", "Email commande", "Code barre", "Nom du tarif", "Prix ticket", "Informations supplémentaires-1"]
      };
      var getindex=';'
      var initgl=0;
      var lidm=[];
      var lids1=[];
      var dataEditAll;
      var epp=10; // items par page
      var p=1; // page séléctionnée
      var gl=0 // longeur de la data
      var maxP=0; // nombre de page
      var tabselp=[];
      var cus=['<span style="color:#ff8239;background-color:#ff823930">','</span>'];
      t.boService.getAllKeys().then(data => {
        dataKeys=data
        if(dataKeys.length>0){
          for(var i=0;i<dataKeys.length;i++){
            dataKeys[i]=dataKeys[i].value;
            if(dataKeys[i].name==undefined){
              dataKeys[i].name='Accès sans nom';
            };
          };
        };
      }).catch(error => {
        t.boService.errorLoad();
      });
      t.boService.getAllParticipants().then(data => {
        g0=data;
        if(g0.length>0){
          for(var i=0;i<g0.length;i++){
            glist=glist.concat(g0[i]);
          };
        };
        if(glist.length>0){
          for(var i=0;i<glist.length;i++){
            glist[i].id = glist[i]._id;
            glist[i].key = glist[i].key;
            glist[i] = glist[i];
            glist[i].index=i;
            if(!glist[i].dateRequest){glist[i].dateRequest="2000-01-01T00:00:00.000Z"};
            var srt0=glist[i].dateRequest.split('T');
            var srt1=srt0[0].split('-');
            var srt2=srt0[1].split(':');
            var srt3=srt2[2].split('.');
            var srt4=srt1[0]+srt1[1]+srt1[2]+srt2[0]+srt2[1]+srt3[0];
            glist[i]['fullDate']=srt1[2]+'/'+srt1[1]+'/'+srt1[0]+' '+srt2[0]+'h'+srt2[1];
            glist[i]['gSort']=parseFloat(srt4);
            if(!glist[i].email){glist[i].email=""};
            if(!glist[i].firstNameUser){glist[i].firstNameUser=""};
            if(!glist[i].lastNameUser){glist[i].lastNameUser=""};
            var firstName=glist[i].firstNameUser;
            var lastName=glist[i].lastNameUser;
            if(!glist[i].firstAndLastName){
              glist[i].firstAndLastName=lastName.substr(0,1).toUpperCase()+lastName.substr(1).toLowerCase()+' '+firstName.substr(0,1).toUpperCase()+firstName.substr(1).toLowerCase();
            };
            if(!glist[i].keyRandom){glist[i].keyRandom=""};
            if(!glist[i].urlTicket){glist[i].urlTicket=""};
            if(!glist[i].idTplTicket){glist[i].idTplTicket=""};
            var nameTpl='';
            var maxscan=1;
            for(var j=0;j<dataListTpl.length;j++){
              if(dataListTpl[j].id==glist[i].idTplTicket){
                nameTpl=dataListTpl[j].name;
                maxscan=dataListTpl[j].scanNbr;
                break;
              };
            };
            glist[i].nameTpl=nameTpl;
            glist[i].maxscan=maxscan;
            var totScan=0;
            var totScanIn=0;
            var totScanOut=0;
            var tabScanIn=[];
            var tabScanOut=[];
            if(glist[i].scans){
              if(glist[i].scans[idlist]){
                if(glist[i].scans[idlist].nbrScan){
                  totScanIn+=glist[i].scans[idlist].nbrScan;
                  totScan+=glist[i].scans[idlist].nbrScan;
                };
                if(glist[i].scans[idlist].nbrDeScan){
                  totScanOut+=glist[i].scans[idlist].nbrDeScan;
                  totScan-=glist[i].scans[idlist].nbrDeScan;
                };
                if(glist[i].scans[idlist].dateScan){
                  tabScanIn = tabScanIn.concat(glist[i].scans[idlist].dateScan);
                };
                if(glist[i].scans[idlist].dateDescan){
                  tabScanOut = tabScanOut.concat(glist[i].scans[idlist].dateDescan);
                };
              };
            }else{
              glist[i].scans={};
            };
            if(totScan<0){totScan=0};
            glist[i].totScan=totScan;
            glist[i].totScanIn=totScanIn;
            glist[i].totScanOut=totScanOut;
            glist[i].tabScanIn=tabScanIn;
            glist[i].tabScanOut=tabScanOut;
            if(!glist[i].flag){glist[i].flag='false'};
            if(!glist[i].ban){glist[i].ban='false'};
          };
        };
        initgl=glist.length;
        if(initgl<=0){
          $('.contPar').html('<div class="txtnoX"><i class="far fa-info-circle"></i> &nbsp;Vous n\'avez pas encore de participant.</div>');
        };
        glist=glist.sort(function(a,b){return parseInt(b.gSort)-parseInt(a.gSort)});
        for(var i=0;i<glist.length;i++){glist[i].index=i;};
        loadLP(glist);
        $('.blocLoader').fadeOut(300);
      })
      .catch(error => {
        t.boService.errorLoad();
      });
      function loadLP(e){
        gl=e.length;
        $('#mtotpar').html(gl.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
        maxP=Math.ceil(gl/epp);
        if((maxP)<=1){
          $('.cplp:eq(1), .cplp:eq(2)').css({'display':'none'});$('.btnNextLP').addClass('cplparroff');
        }else if((maxP)==2){
          $('.cplp:eq(1)').css({'display':'none'});$('.cplp:eq(2)').css({'display':'inline-block'});$('.btnNextLP').removeClass('cplparroff');
        }else{
          $('.cplp:eq(1)').css({'display':'inline-block'});$('.cplp:eq(2)').css({'display':'inline-block'});$('.btnNextLP').removeClass('cplparroff');
        };
        if(maxP==0){maxP=1};
        $('.cplp:eq(2)').html(maxP);
        var nbrlp=epp; // nb items restant sur la dernière page
        var checkpp=0; // nb d'items checkés par page
        if(p==maxP){nbrlp=gl-(((maxP)*epp)-epp)};
        if(epp>=gl){nbrlp=gl};
        if(nbrlp==0){nbrlp=epp};
        if(gl==0||gl==1){
          $('.rpp').html(gl);
        }else{
          $('.rpp').html( (((p-1)*epp)+1)+' à '+(((p-1)*epp)+nbrlp) );
        };
        $('.contPar').html('');
        if(gl>0){
          for(var i=((p-1)*epp);i<(((p-1)*epp)+nbrlp);i++){
            var listyle='';
            var licheckprop='';
            if(tabselp.length==initgl){
              listyle='color:#ff8239;box-shadow:inset 0px 0px 50px 5px #ffbb9440;';
              licheckprop='checked';
              checkpp=nbrlp;
            }else if(tabselp.some(check=>check.index===e[i].index)){
              listyle='color:#ff8239;box-shadow:inset 0px 0px 50px 5px #ffbb9440;';
              licheckprop='checked';
              checkpp++;
            };
            if(tabselp.length>=gl&&checkpp==nbrlp){
              $('#inallsel').prop('checked',true);
            }else{
              $('#inallsel').prop('checked',false);
            };
            var liclass='lipar';if(i%2==0){liclass+=' lpg'};
            var likeyrandom='';
            if(e[i].keyRandom){likeyrandom=e[i].keyRandom};
            var lidate='';
            if(e[i].fullDate){lidate=e[i].fullDate};

            var liname='';
            if(e[i].firstAndLastName){liname=e[i].firstAndLastName};

            var limail='';
            if(e[i].email){limail=e[i].email};
            var linametpl='';
            if(e[i].nameTpl){linametpl=e[i].nameTpl};
            var linbscan=0;
            if(e[i].totScan){linbscan=e[i].totScan};
            var limaxscan=0;
            if(e[i].maxscan){limaxscan=e[i].maxscan};
            var cs='cs0';
            if(linbscan>0){
              cs='cs1';
              if(linbscan>=limaxscan){
                cs='cs2';
              };
            };
            if(term){
              likeyrandom = highlight(likeyrandom,term);
              lidate = highlight(lidate,term);
              liname = highlight(liname,term);
              limail = highlight(limail,term);
              linametpl = highlight(linametpl,term);
            };
            if(liname.length==0){liname='...'};
            if(limail.length==0){limail='...'};
            if(likeyrandom.length==0){likeyrandom='...'};
            $('.contPar').append('<div class="'+liclass+'" id="'+e[i].id+'" index="'+e[i].index+'" style="'+listyle+'"><div class="ltc ltcf"><input type="checkbox" class="insel" id="insel'+e[i].id+'" '+licheckprop+'><label class="labsel" for="insel'+e[i].id+'"><i class="fas fa-check icsel"></i></label></div><div class="ltc ltcdate">'+lidate+'</div><div class="ltc ltcname">'+liname+'</div><div class="ltc ltcmail">'+limail+'</div><div class="ltc ltctpl">'+linametpl+'</div><div class="ltc ltckey">'+likeyrandom+'</div><div class="ltc ltccontrol"><div class="contcirclescan"><div class="lscan"><i class="fa-solid fa-minus"></i></div><div class="circlescan '+cs+'"><span class="nbscan">'+linbscan+'</span><span class="maxscan"> / '+limaxscan+'</span></div><div class="mscan"><i class="fa-solid fa-plus"></i></div></div></div></div>');
          };
          checkline();
          detpar();
          if(getcust.length>3){
            $('.searchPar').val(getcust);
            term=getcust;
            getcust='00';
            t.boService.getcust='0';
            searchLP();
          }else if(getcust=='00'){
            getcust='0';
            $('.ltckey').trigger('click');
          };
        }else{
          if(term){
            $('.contPar').html('<div class="txtnoX"><i class="far fa-info-circle"></i> &nbsp;Aucun résultat trouvé.<br><br><span style="font-weight:800">Veuillez réessayer avec un autre mot clé.</span></div>');
          }else if(b1on==1 || b2on==1 || b3on==1 || b4on==1){
            $('.contPar').html('<div class="txtnoX"><i class="far fa-info-circle"></i> &nbsp;Aucun résultat trouvé.<br><br><span style="font-weight:800">Veuillez réessayer avec d\'autres filtres.</span></div>');
          }else{
            $('.contPar').html('<div class="txtnoX"><i class="far fa-info-circle"></i> &nbsp;Vous n\'avez pas encore de participant.</div>');
          };
        };
      };
      function highlight(a,b){
        var splitMod=0;
        var termtxt='';
        var lis=a.split(b);
        if(lis.length==1){lis=a.split(b.toUpperCase());splitMod=1;};
        if(lis.length==1){lis=a.split(b.substr(0,1).toUpperCase()+b.substr(1));splitMod=2;};
        if(lis.length==1){lis=a.split(b.substr(0,1)+b.substr(1).toLowerCase());splitMod=3;};
        if(lis.length==1){lis=a.split(b.toLowerCase());splitMod=4;};
        if(lis.length>1){
          a='';
          for(var d=0;d<lis.length;d++){
            if(d!=(lis.length)-1){
              if(splitMod==1){termtxt=b.toUpperCase();
              }else if(splitMod==2){termtxt=b.substr(0,1).toUpperCase()+b.substr(1);
              }else if(splitMod==3){termtxt=b.substr(0,1)+b.substr(1).toLowerCase();
              }else if(splitMod==4){termtxt=b.toLowerCase();
              }else{termtxt=b;};
              a+=lis[d]+cus[0]+termtxt+cus[1];
            }else{
              a+=lis[d];
            };
          };
        };
        return a;
      };
      //--------------------------------------------------------------------------
      //--- Fonctions / Actions  : -----------------------------------------------
      $('.bulf').mouseover(function(){
        var tcf=$(this).attr('tag');
        if(tcf=='bul1'){
          $('.infobulf').html('Non-composté');
        }else if(tcf=='bul2'){
          $('.infobulf').html('Composté');
        }else if(tcf=='bul3'){
          $('.infobulf').html('Bloqué');
        }else if(tcf=='bul4'){
          $('.infobulf').html('Marqué');
        }else{
          $('.infobulf').html('');
        };
      });
      $('.bulf').mouseout(function(){
        $('.infobulf').html('');
      });
      var b1on=0;
      var b2on=0;
      var b3on=0;
      var b4on=0;
      $('.bul0').click(function(){
        if($(this).attr('class')=='bulf bul0'){
          $(this).addClass('bul0on');
          $('.bul1,.bul2,.bul3,.bul4').addClass('bul1234on');
        }else{
          $(this).removeClass('bul0on');
          $('.bul1,.bul2,.bul3,.bul4').removeClass('bulact').removeClass('bul1234on');
          $('.infobulf').html('');
          b1on=0;
          b2on=0;
          b3on=0;
          b4on=0;
          searchLP();
        };
      });
      $('.bul1').click(function(){
        if($(this).attr('class')=='bulf bul1 bul1234on'){
          $(this).addClass('bulact');
          b1on=1;
        }else{
          $(this).removeClass('bulact');
          b1on=0;
        };
        searchLP();
      });
      $('.bul2').click(function(){
        if($(this).attr('class')=='bulf bul2 bul1234on'){
          $(this).addClass('bulact');
          b2on=1;
        }else{
          $(this).removeClass('bulact');
          b2on=0;
        };
        searchLP();
      });
      $('.bul3').click(function(){
        if($(this).attr('class')=='bulf bul3 bul1234on'){
          $(this).addClass('bulact');
          b3on=1;
        }else{
          $(this).removeClass('bulact');
          b3on=0;
        };
        searchLP();
      });
      $('.bul4').click(function(){
        if($(this).attr('class')=='bulf bul4 bul1234on'){
          $(this).addClass('bulact');
          b4on=1;
        }else{
          $(this).removeClass('bulact');
          b4on=0;
        };
        searchLP();
      });
      function checkline(){
        $('#inallsel').change(function(){
          if($(this).is(':checked')){
            if(dataSearch.length>0){tabselp=dataSearch}else{tabselp=glist};
            var tsl=tabselp.length;
            $('.insel').prop('checked',true);
            $('.lipar').css({'color':'#ff8239','box-shadow': 'inset 0px 0px 50px 5px #ffbb9440'});
            $('.contotpar').addClass('contotparextand');
            $('.cmtp').fadeOut(0);
            $('.countselmt').html(gl).css({'opacity':'1.0'});
            $('.countseltxt').css({'width':'90px','opacity':'1.0'});
            if(tsl>1){
              $('.countseltxt').html('&nbsp; sélectionnés');
            }else{
              $('.countseltxt').html('&nbsp; sélectionné');
            };
            $('.btnaddPar').addClass('validact').html('<span class="cutresp7">Valider &nbsp;</span><i class="fas fa-check"></i>');
            $('.actlp').addClass('actlpon');
            $('.searchPar').addClass('searchParExtand');
          }else{
            tabselp=[];
            $('.insel').prop('checked',false);
            $('.lipar').css({'color':'','box-shadow': 'inset 0px 0px 50px 5px #ffa49a00'});
            $('.contotpar').removeClass('contotparextand');
            $('.countselmt').css({'opacity':'0.0'});
            $('.countseltxt').css({'width':'1px','opacity':'0.0'});
            $('.btnaddPar').removeClass('validact');
            $('.actlp').removeClass('actlpon');
            $('.searchPar').removeClass('searchParExtand');
            setTimeout(function(){
              $('.cmtp').fadeIn(400);
              $('.countselmt').html('');
              $('.countseltxt').html('');
              // $('.btnaddPar').html('<span class="cutresp7"><i class="far fa-plus"></i> &nbsp; </span>Ajouter un participant');
              $('.btnaddPar').html('<span class="cutresp7"><i class="far fa-check-square"></i> &nbsp; </span>Aucune sélection');

            },500);
          };
        });
        $('.insel').change(function(){
          var getid=$(this).parent().parent().attr('id');
          var getindex2=$(this).parent().parent().attr('index');
          if($(this).is(':checked')){
            tabselp.push(glist[getindex2]);
            $('#'+getid+'').css({'color':'#ff8239','box-shadow': 'inset 0px 0px 50px 5px #ffbb9440'});
          }else{
            tabselp = $.grep(tabselp, function(e){
              return e.index != getindex2;
            });
            $('#'+getid+'').css({'color':'','box-shadow': 'inset 0px 0px 50px 5px #ffbb9400'});
          };
          var tsl=tabselp.length;
          if(tsl==gl){
            $('#inallsel').prop('checked',true);
          }else{
            $('#inallsel').prop('checked',false);
          };
          if(tsl>0){
            $('.contotpar').addClass('contotparextand');
            $('.cmtp').fadeOut(0);
            $('.countselmt').html(tsl).css({'opacity':'1.0'});
            $('.countseltxt').css({'width':'90px','opacity':'1.0'});
            if(tsl>1){
              $('.countseltxt').html('&nbsp; sélectionnés');
            }else{
              $('.countseltxt').html('&nbsp; sélectionné');
            };
            $('.btnaddPar').addClass('validact').html('<span class="cutresp7">Valider &nbsp;</span><i class="fas fa-check"></i>');
            $('.actlp').addClass('actlpon');
            $('.searchPar').addClass('searchParExtand');
          }else{
            $('.contotpar').removeClass('contotparextand');
            $('.countselmt').css({'opacity':'0.0'});
            $('.countseltxt').css({'width':'1px','opacity':'0.0'});
            $('.btnaddPar').removeClass('validact');
            $('.actlp').removeClass('actlpon');
            $('.searchPar').removeClass('searchParExtand');
            setTimeout(function(){
              $('.cmtp').fadeIn(400);
              $('.countselmt').html('');
              $('.countseltxt').html('');
              // $('.btnaddPar').html('<span class="cutresp7"><i class="far fa-plus"></i> &nbsp; </span>Ajouter un participant');
              $('.btnaddPar').html('<span class="cutresp7"><i class="far fa-check-square"></i> &nbsp; </span>Aucune sélection');
            },500);
          };
        });
      };
      $('.selepp').change(function(){
        p=1;
        $('.btnPrevLP').addClass('cplparroff');
        $('.btnNextLP').removeClass('cplparroff');
        $('.cplp:eq(0)').addClass('cplpon');
        $('.cplp:eq(2)').removeClass('cplpon');
        $('.cplp:eq(1)').removeClass('cplpon').addClass('cplpoff').html('...');
        var mop=0;
        mop=epp;
        epp=parseInt($(this).val());
        if(mop>epp){mop=0;
        }else{mop=1;};
        maxP=Math.ceil(gl/epp);
        $('.cplp:eq(2)').html(maxP);
        if((maxP)==1){
          $('.cplp:eq(1), .cplp:eq(2)').css({'display':'none'});$('.btnNextLP').addClass('cplparroff');
        }else if((maxP)==2){
          $('.cplp:eq(1)').css({'display':'none'});$('.cplp:eq(2)').css({'display':'inline-block'});$('.btnNextLP').removeClass('cplparroff');
        }else if((maxP)>2){
          $('.cplp:eq(1), .cplp:eq(2)').css({'display':'inline-block'});$('.btnNextLP').removeClass('cplparroff');
        };
        if(dataSearch.length>0){loadLP(dataSearch)}else{loadLP(glist)};
        if(mop==0){
          $('.contPar').animate({scrollTop:0},600);
        }else{
          $('.contPar').animate({scrollTop:$('.contPar')[0].scrollHeight},600);
        };
      });
      $('.btnPrevLP').click(function(){
        p--;
        if(p<maxP){
          $('.btnNextLP').removeClass('cplparroff');
          $('.cplp:eq(2)').removeClass('cplpon');
          $('.cplp:eq(1)').removeClass('cplpoff').addClass('cplpon').html(p);
        };
        if(p<=1){
          p=1;
          $('.btnPrevLP').addClass('cplparroff');
          $('.cplp:eq(0)').addClass('cplpon');
          $('.cplp:eq(1)').removeClass('cplpon').addClass('cplpoff').html('...');
        };
        if(dataSearch.length>0){loadLP(dataSearch)}else{loadLP(glist)};
      });
      $('.btnNextLP').click(function(){
        p++;
        if(p>1){
          $('.btnPrevLP').removeClass('cplparroff');
          $('.cplp:eq(0)').removeClass('cplpon');
          $('.cplp:eq(1)').removeClass('cplpoff').addClass('cplpon').html(p);
        };
        if(p>=maxP){
          p=maxP;
          $('.btnNextLP').addClass('cplparroff');
          $('.cplp:eq(2)').addClass('cplpon');
          $('.cplp:eq(1)').removeClass('cplpon').addClass('cplpoff').html('...');
        };
        if(dataSearch.length>0){loadLP(dataSearch)}else{loadLP(glist)};
      });
      $('.cplp:eq(0)').click(function(){
        p=1;
        $('.btnPrevLP').addClass('cplparroff');
        $('.btnNextLP').removeClass('cplparroff');
        $('.cplp:eq(0)').addClass('cplpon');
        $('.cplp:eq(2)').removeClass('cplpon');
        $('.cplp:eq(1)').removeClass('cplpon').addClass('cplpoff').html('...');
        if(dataSearch.length>0){loadLP(dataSearch)}else{loadLP(glist)};
      });
      $('.cplp:eq(2)').click(function(){
        p=maxP;
        $('.btnPrevLP').removeClass('cplparroff');
        $('.btnNextLP').addClass('cplparroff');
        $('.cplp:eq(0)').removeClass('cplpon');
        $('.cplp:eq(2)').addClass('cplpon');
        $('.cplp:eq(1)').removeClass('cplpon').addClass('cplpoff').html('...');
        if(dataSearch.length>0){loadLP(dataSearch)}else{loadLP(glist)};
      });
      var checlic=0;
      $('.btnaddPar').click(function(){
        if(checlic==0){
          checlic=1;
          if(tabselp.length>0){
            lidm=[];
            $('.savestick').css({'right':'0px'});
            var vsa= $('.selactlp').val();
            if(vsa=='flag'){
              for(var i=0;i<tabselp.length;i++){
                if(glist[tabselp[i].index].flag=='true'){
                  glist[tabselp[i].index].flag='false';
                  lidm.push({'idParticipant':tabselp[i].id, 'data':[{'flag':'false'}]});
                }else{
                  glist[tabselp[i].index].flag='true';
                  lidm.push({'idParticipant':tabselp[i].id, 'data':[{'flag':'true'}]});
                };
              };
              if(lidm.length>0){
                if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
                dataEditAll=lidm;
                t.boService.editAllParticipants(dataEditAll).then(data => {
                  $('.savestick').css({'right':'-260px'});
                  checlic=0;
                })
                .catch(error => {
                  // faire cas erreur
                });
              }else{
                $('.savestick').css({'right':'-260px'});
                checlic=0;
              };
            }else if(vsa=='export'){
              var newtab=[];
              for(var i=0; i<tabselp.length;i++){
                newtab.push({
                  'id':tabselp[i].id,
                  'fullDate':tabselp[i].fullDate,
                  'firstAndLastName':tabselp[i].firstAndLastName,
                  'email':tabselp[i].email,
                  'nameTpl':tabselp[i].nameTpl,
                  'keyRandom':tabselp[i].keyRandom,
                  'totScan':tabselp[i].totScan
                });
              };
              t.boService.createCsv(newtab,"Export - Liste participants",csvOptions);
              $('.savestick').css({'right':'-260px'});
              checlic=0;
            }else if(vsa=='export2'){
              var newtab=[];
              for(var i=0; i<tabselp.length;i++){
                newtab.push({
                  'lastNameUser':tabselp[i].lastNameUser,
                  'firstNameUser':tabselp[i].firstNameUser,
                  'email':tabselp[i].email,
                  'keyRandom':tabselp[i].keyRandom,
                  'nameTpl':tabselp[i].nameTpl,
                  'prix':'',
                  'infos':''
                });
              };
              t.boService.createCsv(newtab,"Export - Liste participants",csvOptions2);
              $('.savestick').css({'right':'-260px'});
              checlic=0;
            }else if(vsa=='compost'){
              var newDate=new Date();
              var year=newDate.getFullYear();
              var month=(newDate.getMonth())+1;
              var monthTxt=month.toString();
              if(month<10){monthTxt='0'+monthTxt};
              var day=newDate.getDate();
              var dayTxt=day.toString();
              if(day<10){dayTxt='0'+dayTxt};
              var hour=newDate.getHours();
              var hourTxt=hour.toString();
              if(hour<10){hourTxt='0'+hourTxt};
              var minute=newDate.getMinutes();
              var minuteTxt=minute.toString();
              if(minute<10){minuteTxt='0'+minuteTxt};
              var second=newDate.getSeconds();
              var secondTxt=second.toString();
              if(second<10){secondTxt='0'+secondTxt};
              var fullDateTxt=''+dayTxt+'-'+monthTxt+'-'+year+' '+hourTxt+':'+minuteTxt+':'+secondTxt+'';
              for(var i=0;i<tabselp.length;i++){
                var tdx=tabselp[i].index;
                var ttotscan=glist[tdx].totScan;
                var tmaxscan=glist[tdx].maxscan;
                if(ttotscan<tmaxscan){
                  if(glist[tdx].scans[idlist]){
                    if(glist[tdx].scans[idlist].nbrScan){
                      glist[tdx].scans[idlist].nbrScan++;
                      glist[tdx].scans[idlist].dateScan.push({'date':fullDateTxt,'idStaff':'fromBo'});
                    }else{
                      glist[tdx].scans[idlist]['nbrScan']=1;
                      glist[tdx].scans[idlist]['dateScan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                    };
                  }else{
                    glist[tdx].scans[idlist]={};
                    glist[tdx].scans[idlist]['nbrScan']=1;
                    glist[tdx].scans[idlist]['dateScan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                  };
                  glist[tdx].totScan++;
                  glist[tdx].totScanIn++;
                  glist[tdx].tabScanIn.push({'date':fullDateTxt,'idStaff':'fromBo'});
                  lidm.push({'idParticipant':tabselp[i].id, 'data':[{'scans':glist[tdx].scans}]});
                };
              };
              if(lidm.length>0){
                if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
                dataEditAll=lidm;
                t.boService.editAllParticipants(dataEditAll).then(data => {
                  $('.savestick').css({'right':'-260px'});
                  checlic=0;
                })
                .catch(error => {
                  // faire cas erreur
                });
              }else{
                $('.savestick').css({'right':'-260px'});
                checlic=0;
              };
            }else if(vsa=='decompost'){
              var newDate=new Date();
              var year=newDate.getFullYear();
              var month=(newDate.getMonth())+1;
              var monthTxt=month.toString();
              if(month<10){monthTxt='0'+monthTxt};
              var day=newDate.getDate();
              var dayTxt=day.toString();
              if(day<10){dayTxt='0'+dayTxt};
              var hour=newDate.getHours();
              var hourTxt=hour.toString();
              if(hour<10){hourTxt='0'+hourTxt};
              var minute=newDate.getMinutes();
              var minuteTxt=minute.toString();
              if(minute<10){minuteTxt='0'+minuteTxt};
              var second=newDate.getSeconds();
              var secondTxt=second.toString();
              if(second<10){secondTxt='0'+secondTxt};
              var fullDateTxt=''+dayTxt+'-'+monthTxt+'-'+year+' '+hourTxt+':'+minuteTxt+':'+secondTxt+'';
              for(var i=0;i<tabselp.length;i++){
                var tdx=tabselp[i].index;
                var ttotscan=glist[tdx].totScan;
                if(ttotscan>0){
                  if(glist[tdx].scans[idlist]){
                    if(glist[tdx].scans[idlist].nbrDeScan){
                      glist[tdx].scans[idlist].nbrDeScan++;
                      glist[tdx].scans[idlist].dateDescan.push({'date':fullDateTxt,'idStaff':'fromBo'});
                    }else{
                      glist[tdx].scans[idlist]['nbrDeScan']=1;
                      glist[tdx].scans[idlist]['dateDescan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                    };
                  }else{
                    glist[tdx].scans[idlist]={};
                    glist[tdx].scans[idlist]['nbrDeScan']=1;
                    glist[tdx].scans[idlist]['dateDescan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                  };
                  glist[tdx].totScan--;
                  glist[tdx].totScanOut++;
                  glist[tdx].tabScanOut.push({'date':fullDateTxt,'idStaff':'fromBo'});
                  lidm.push({'idParticipant':tabselp[i].id, 'data':[{'scans':glist[tdx].scans}]});
                };
              };
              if(lidm.length>0){
                if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
                dataEditAll=lidm;
                t.boService.editAllParticipants(dataEditAll).then(data => {
                  $('.savestick').css({'right':'-260px'});
                  checlic=0;
                })
                .catch(error => {
                  // faire cas erreur
                });
              }else{
                $('.savestick').css({'right':'-260px'});
                checlic=0;
              };
            }else if(vsa=='ban'){
              for(var i=0;i<tabselp.length;i++){
                if(glist[tabselp[i].index].ban=='true'){
                  glist[tabselp[i].index].ban='false';
                   lidm.push({'idParticipant':tabselp[i].id, 'data':[{'ban':'false'}]});
                }else{
                  glist[tabselp[i].index].ban='true';
                   lidm.push({'idParticipant':tabselp[i].id, 'data':[{'ban':'true'}]});
                };
              };
              if(lidm.length>0){
                if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
                dataEditAll=lidm;
                t.boService.editAllParticipants(dataEditAll).then(data => {
                  $('.savestick').css({'right':'-260px'});
                  checlic=0;
                })
                .catch(error => {
                  // faire cas erreur
                });
              }else{
                $('.savestick').css({'right':'-260px'});
                checlic=0;
              };
            };
          }else{
            $('.txtnoX').remove();
            $('.searchPar').val('');
            term='';
            searchLP();
            // ajout participant;
          };
        };
      });
      var dataSearch=[];
      var term='';
      var checksearch=0;
      function searchLP(){
        checksearch=0;
        dataSearch=glist;
        if(b1on==1){
          dataSearch=dataSearch.filter(function(e){return(e.totScan==0);});
          checksearch=1;
        };
        if(b2on==1){
          dataSearch=dataSearch.filter(function(e){return(e.totScan!=0);});
          checksearch=1;
        };
        if(b3on==1){
          dataSearch=dataSearch.filter(function(e){return(e.ban=='true');});
          checksearch=1;
        };
        if(b4on==1){
          dataSearch=dataSearch.filter(function(e){return(e.flag=='true');});
          checksearch=1;
        };
        if(term){
          $('.cleanSearch').css({'opacity':'1','pointer-events':'visible'});
          $('.icparld').html(' &nbsp;<i class="fas fa-search"></i>');
          dataSearch=dataSearch.filter(function(e){
            return(e.keyRandom.search(term)!=-1)
              ||(e.keyRandom.search(term.toLowerCase())!=-1)
              ||(e.fullDate.search(term)!=-1)
              ||(e.fullDate.search(term.toLowerCase())!=-1)
              ||(e.firstAndLastName.search(term)!=-1)
              ||(e.firstAndLastName.search(term.toUpperCase())!=-1)
              ||(e.firstAndLastName.search(term.substr(0,1)+term.substr(1).toLowerCase())!=-1)
              ||(e.firstAndLastName.search(term.substr(0,1).toUpperCase()+term.substr(1))!=-1)
              ||(e.firstAndLastName.search(term.toLowerCase())!=-1)
              ||(e.email.search(term)!=-1)
              ||(e.email.search(term.toUpperCase())!=-1)
              ||(e.email.search(term.substr(0,1)+term.substr(1).toLowerCase())!=-1)
              ||(e.email.search(term.substr(0,1).toUpperCase()+term.substr(1))!=-1)
              ||(e.email.search(term.toLowerCase())!=-1)
              ||(e.nameTpl.search(term)!=-1)
              ||(e.nameTpl.search(term.toUpperCase())!=-1)
              ||(e.nameTpl.search(term.substr(0,1)+term.substr(1).toLowerCase())!=-1)
              ||(e.nameTpl.search(term.substr(0,1).toUpperCase()+term.substr(1))!=-1)
              ||(e.nameTpl.search(term.toLowerCase())!=-1);
          });
          checksearch=1;
        }else{
          $('.cleanSearch').css({'opacity':'0','pointer-events':'none'});
          $('.icparld').html(' &nbsp;<i class="fas fa-users"></i>')
        };
        if(checksearch==1){
          p=1;
          loadLP(dataSearch);
        }else{
          dataSearch=[];
          loadLP(glist);
        };
      };
      $('.cleanSearch').click(function(){
        $('.searchPar').val('');
        term='';
        searchLP();
      });
      function refreshIndex(){
        for(var i=0;i<glist.length;i++){glist[i].index=i;};
      };
      $('.searchPar').keyup(function(){
        term=$(this).val().toString()||'';
        searchLP();
      });
      $('.htc').click(function(){
        var th=$(this);
        var gt=th.attr('tag');
        if(th.attr('id')=='triondown'){
          th.attr('id','trionup');
          if(gt=='1'){
            glist=glist.sort(function(a,b){return parseInt(a.gSort)-parseInt(b.gSort)});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='2'){
            glist=glist.sort(function(a,b){if(b.firstAndLastName<a.firstAndLastName){return -1};if(b.firstAndLastName>a.firstAndLastName){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='3'){
            glist=glist.sort(function(a,b){if(b.email<a.email){return -1};if(b.email>a.email){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='4'){
            glist=glist.sort(function(a,b){if(b.nameTpl<a.nameTpl){return -1};if(b.nameTpl>a.nameTpl){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='6'){
            glist=glist.sort(function(a,b){return parseInt(a.totScan)-parseInt(b.totScan)});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          };
        }else if(th.attr('id')=='trionup'){
          th.attr('id','triondown');
          if(gt=='1'){
            glist=glist.sort(function(a,b){return parseInt(b.gSort)-parseInt(a.gSort)});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='2'){
            glist=glist.sort(function(a,b){if(a.firstAndLastName<b.firstAndLastName){return -1};if(a.firstAndLastName>b.firstAndLastName){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='3'){
            glist=glist.sort(function(a,b){if(a.email<b.email){return -1};if(a.email>b.email){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='4'){
            glist=glist.sort(function(a,b){if(a.nameTpl<b.nameTpl){return -1};if(a.nameTpl>b.nameTpl){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='6'){
            glist=glist.sort(function(a,b){return parseInt(b.totScan)-parseInt(a.totstotScancan)});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          };
        }else{
          $('.htc').attr('id','');
          th.attr('id','triondown');
          if(gt=='1'){
            glist=glist.sort(function(a,b){return parseInt(b.gSort)-parseInt(a.gSort)});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='2'){
            glist=glist.sort(function(a,b){if(a.firstAndLastName<b.firstAndLastName){return -1};if(a.firstAndLastName>b.firstAndLastName){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='3'){
            glist=glist.sort(function(a,b){if(a.email<b.email){return -1};if(a.email>b.email){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='4'){
            glist=glist.sort(function(a,b){if(a.nameTpl<b.nameTpl){return -1};if(a.nameTpl>b.nameTpl){return 1};return 0;});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          }else if(gt=='6'){
            glist=glist.sort(function(a,b){return parseInt(b.totScan)-parseInt(a.totScan)});
            refreshIndex();
            if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          };
        };
      });
      var dataCustomer={};
      var checlms=0;
      $('.contPar').on('click','.lscan,.mscan',function(){
        if(checlms==0){
          checlms=1;
          var th=$(this);
          var tclass=th.attr('class');
          var tpar=th.parent().parent().parent();
          var tid=tpar.attr('id');
          var tindex=tpar.attr('index');
          var ttotscan=glist[tindex].totScan;
          var tmaxscan=glist[tindex].maxscan;
          var tcs=tpar.find('.circlescan');
          var tns=tpar.find('.nbscan');
          var cs='cs1';
          var newDate=new Date();
          var year=newDate.getFullYear();
          var month=(newDate.getMonth())+1;
          var monthTxt=month.toString();
          if(month<10){monthTxt='0'+monthTxt};
          var day=newDate.getDate();
          var dayTxt=day.toString();
          if(day<10){dayTxt='0'+dayTxt};
          var hour=newDate.getHours();
          var hourTxt=hour.toString();
          if(hour<10){hourTxt='0'+hourTxt};
          var minute=newDate.getMinutes();
          var minuteTxt=minute.toString();
          if(minute<10){minuteTxt='0'+minuteTxt};
          var second=newDate.getSeconds();
          var secondTxt=second.toString();
          if(second<10){secondTxt='0'+secondTxt};
          var fullDateTxt=''+dayTxt+'-'+monthTxt+'-'+year+' '+hourTxt+':'+minuteTxt+':'+secondTxt+'';
          if((tclass=='lscan'&&ttotscan>0)||(tclass=='mscan'&&ttotscan<tmaxscan)){
            setTimeout(function(){
              if(checlms==1){
                th.html('<i class="fa-solid fa-spinner-third fa-spin"></i>');
              };
            },500);
            dataCustomer['scans']=Object.assign({},glist[tindex].scans);
            if(tclass=='lscan'){
              if(glist[tindex].scans[idlist]){
                if(dataCustomer['scans'][idlist].nbrDeScan){
                  dataCustomer['scans'][idlist].nbrDeScan++;
                  dataCustomer['scans'][idlist].dateDescan.push({'date':fullDateTxt,'idStaff':'fromBo'});
                }else{
                  dataCustomer['scans'][idlist]['nbrDeScan']=1;
                  dataCustomer['scans'][idlist]['dateDescan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                };
              }else{
                dataCustomer['scans'][idlist]={};
                dataCustomer['scans'][idlist]['nbrDeScan']=1;
                dataCustomer['scans'][idlist]['dateDescan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
              };
            }else if(tclass=='mscan'){
              if(glist[tindex].scans[idlist]){
                if(dataCustomer['scans'][idlist].nbrScan){
                  dataCustomer['scans'][idlist].nbrScan++;
                  dataCustomer['scans'][idlist].dateScan.push({'date':fullDateTxt,'idStaff':'fromBo'});
                }else{
                  dataCustomer['scans'][idlist]['nbrScan']=1;
                  dataCustomer['scans'][idlist]['dateScan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                };
              }else{
                dataCustomer['scans'][idlist]={};
                dataCustomer['scans'][idlist]['nbrScan']=1;
                dataCustomer['scans'][idlist]['dateScan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
              };
            };
            tns.css({'transform':'scale(1.5) translateX(-3px)'});
            t.boService.editParticipant(tid,dataCustomer).then(data => {
              glist[tindex].scans=dataCustomer['scans'];
              if(tclass=='lscan'){
                glist[tindex].totScan--;
                glist[tindex].totScanOut++;
                glist[tindex].tabScanOut.push({'date':fullDateTxt,'idStaff':'fromBo'});
                if(glist[tindex].totScan<=0){cs='cs0';};
                th.html('<i class="fa-solid fa-minus"></i>');
              }else if(tclass=='mscan'){
                glist[tindex].totScan++;
                glist[tindex].totScanIn++;
                glist[tindex].tabScanIn.push({'date':fullDateTxt,'idStaff':'fromBo'});
                if(glist[tindex].totScan>=glist[tindex].maxscan){cs='cs2';};
                th.html('<i class="fa-solid fa-plus"></i>');
              };
              tcs.attr('class','circlescan '+cs+'');
              tns.html(glist[tindex].totScan);
              setTimeout(function(){tns.css({'transform':'scale(1.0) translateX(0px)'});},200);
              dataCustomer={};
              checlms=0;
            }).catch(error => {
              dataCustomer={};
            });
          }else{
            tcs.css({'transform':'rotate(25deg)'});
            setTimeout(function(){tcs.css({'transform':'rotate(-15deg)'});},150);
            setTimeout(function(){tcs.css({'transform':'rotate(10deg)'});},300);
            setTimeout(function(){tcs.css({'transform':'rotate(-5deg)'});},450);
            setTimeout(function(){tcs.css({'transform':'rotate(0deg)'});},600);
            checlms=0;
          };
        };
      });
      $('.lscandet,.mscandet').click(function(){
        if(checlms==0){
          checlms=1;
          var th=$(this);
          var tclass=th.attr('class');
          var tpar=th.parent();
          var ttotscan=glist[getindex].totScan;
          var tmaxscan=glist[getindex].maxscan;
          var tcs=tpar.find('.mtsoldepar');
          var tns=tpar.find('.detvalscan');
          var cs='cs1';
          var cltop='';
          var newDate=new Date();
          var year=newDate.getFullYear();
          var month=(newDate.getMonth())+1;
          var monthTxt=month.toString();
          if(month<10){monthTxt='0'+monthTxt};
          var day=newDate.getDate();
          var dayTxt=day.toString();
          if(day<10){dayTxt='0'+dayTxt};
          var hour=newDate.getHours();
          var hourTxt=hour.toString();
          if(hour<10){hourTxt='0'+hourTxt};
          var minute=newDate.getMinutes();
          var minuteTxt=minute.toString();
          if(minute<10){minuteTxt='0'+minuteTxt};
          var second=newDate.getSeconds();
          var secondTxt=second.toString();
          if(second<10){secondTxt='0'+secondTxt};
          var fullDateTxt=''+dayTxt+'-'+monthTxt+'-'+year+' '+hourTxt+':'+minuteTxt+':'+secondTxt+'';
          if((tclass=='lscandet'&&ttotscan>0)||(tclass=='mscandet'&&ttotscan<tmaxscan)){
            setTimeout(function(){
              if(checlms==1){
                th.html('<i class="fa-solid fa-spinner-third fa-spin"></i>');
              };
            },500);
            dataCustomer['scans']=Object.assign({},glist[getindex].scans);
            if(tclass=='lscandet'){
              if(glist[getindex].scans[idlist]){
                if(dataCustomer['scans'][idlist].nbrDeScan){
                  dataCustomer['scans'][idlist].nbrDeScan++;
                  dataCustomer['scans'][idlist].dateDescan.push({'date':fullDateTxt,'idStaff':'fromBo'});
                }else{
                  dataCustomer['scans'][idlist]['nbrDeScan']=1;
                  dataCustomer['scans'][idlist]['dateDescan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                };
              }else{
                dataCustomer['scans'][idlist]={};
                dataCustomer['scans'][idlist]['nbrDeScan']=1;
                dataCustomer['scans'][idlist]['dateDescan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
              };
            }else if(tclass=='mscandet'){
              if(glist[getindex].scans[idlist]){
                if(dataCustomer['scans'][idlist].nbrScan){
                  dataCustomer['scans'][idlist].nbrScan++;
                  dataCustomer['scans'][idlist].dateScan.push({'date':fullDateTxt,'idStaff':'fromBo'});
                }else{
                  dataCustomer['scans'][idlist]['nbrScan']=1;
                  dataCustomer['scans'][idlist]['dateScan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
                };
              }else{
                dataCustomer['scans'][idlist]={};
                dataCustomer['scans'][idlist]['nbrScan']=1;
                dataCustomer['scans'][idlist]['dateScan']=[{'date':fullDateTxt,'idStaff':'fromBo'}];
              };
            };
            tns.css({'transform':'scale(1.5) translateX(-1px)'});
            t.boService.editParticipant(getpar,dataCustomer).then(data => {
              glist[getindex].scans=dataCustomer['scans'];
              countaddconrol++;
              var type='';
              if(tclass=='lscandet'){
                glist[getindex].totScan--;
                glist[getindex].totScanOut++;
                glist[getindex].tabScanOut.push({'date':fullDateTxt,'idStaff':'fromBo'});
                var indexscan=glist[getindex].totScanOut-1;
                //if(glist[getindex].totScan<=0){cs='cs0';};
                if(countaddconrol%2==0){
                  cltop='litranspar ltpg';
                }else{
                  cltop='litranspar';
                };
                cltop+=' ltpout';
                type='out';
                $('.conttranspar').prepend('<div class="'+cltop+'" type="'+type+'" index="'+indexscan+'"><div class="typscan"><i class="fa-solid fa-arrow-up-from-line" style="transform:rotate(-90deg)"></i> &nbsp;Sortie</div><div class="accesscan">Backoffice</div><div class="datscan">'+fullDateTxt+'</div><div class="actscan"><i class="fa-solid fa-trash-can removescan"></i><span class="cancelrem">Annuler</span></div><div class="confrem">Supprimer</div></div>');
                th.html('<i class="fa-solid fa-minus"></i>');
              }else if(tclass=='mscandet'){
                glist[getindex].totScan++;
                glist[getindex].totScanIn++;
                glist[getindex].tabScanIn.push({'date':fullDateTxt,'idStaff':'fromBo'});
                var indexscan=glist[getindex].totScanIn-1;
                //if(glist[getindex].totScan>=glist[getindex].maxscan){cs='cs2';};
                if(countaddconrol%2==0){
                  cltop='litranspar ltpg';
                }else{
                  cltop='litranspar';
                };
                cltop+=' ltpin';
                type='in';
                $('.messdettrans').remove();
                $('.conttranspar').prepend('<div class="'+cltop+'" type="'+type+'" index="'+indexscan+'"><div class="typscan"><i class="fa-solid fa-arrow-up-to-line" style="transform:rotate(90deg)"></i> &nbsp;Entrée</div><div class="accesscan">Backoffice</div><div class="datscan">'+fullDateTxt+'</div><div class="actscan"><i class="fa-solid fa-trash-can removescan"></i><span class="cancelrem">Annuler</span></div><div class="confrem">Supprimer</div>');
                th.html('<i class="fa-solid fa-plus"></i></div>');
              };
              var totscand=glist[getindex].totScan;
              var cs='cs0';
              if(totscand>0){
                cs='cs1';
                if(totscand>=tmaxscan){
                  cs='cs2';
                };
              };
              $('#'+getpar+'').find('.circlescan').attr('class','circlescan '+cs+'');
              $('#'+getpar+'').find('.nbscan').html(totscand);
              tns.html(totscand);
              setTimeout(function(){tns.css({'transform':'scale(1.0) translateX(0px)'});},200);
              dataCustomer={};
              checlms=0;
            }).catch(error => {
              dataCustomer={};
            });
          }else{
            tcs.css({'transform':'rotate(25deg)'});
            setTimeout(function(){tcs.css({'transform':'rotate(-15deg)'});},150);
            setTimeout(function(){tcs.css({'transform':'rotate(10deg)'});},300);
            setTimeout(function(){tcs.css({'transform':'rotate(-5deg)'});},450);
            setTimeout(function(){tcs.css({'transform':'rotate(0deg)'});},600);
            checlms=0;
          };
        };
      });
      var getpar='';
      var cumTabScan=[];
      var countaddconrol=0;
      function detpar(){
        $('.ltcdate,.ltcname,.ltcmail,.ltctpl,.ltckey').click(function(){
          $('.conttranspar').html('<div class="messdettrans">Chargement ...</div>');
          getpar=$(this).parent().attr('id');
          getindex=$(this).parent().attr('index');
          countaddconrol=0;
          var valastname=glist[getindex].lastNameUser;
          var valfirstname=glist[getindex].firstNameUser;
          var valmail=glist[getindex].email;
          var valkey=glist[getindex].keyRandom;
          var valtplname=glist[getindex].nameTpl;
          $('#linkticket').hide();
          t.ticketingService.recreateParticipant(glist[getindex].idEvent, glist[getindex].idTplTicket, glist[getindex].id).then((result: any) => {
            $('#linkticket').attr('href', result.response);
            $('#linkticket').show();
          })
          var valurlpdf=glist[getindex].urlTicket;
          var valmaxscan=glist[getindex].maxscan;
          var valtotscan=glist[getindex].totScan;
          $('.accountnum').html('n° '+getpar+'');
          $('.informlastname').val(valastname);
          $('.informfirstname').val(valfirstname);
          $('.informmail').html(valmail);
          $('.nfcnumberpar').html(valkey);
          $('.valnfctype').html(valtplname);
          $('.mtsoldepar').html('<span class="detvalscan">'+valtotscan+'</span>/'+valmaxscan);
          cumTabScan=[];
          for(var i=0; i<glist[getindex].tabScanIn.length;i++){
            var d0=glist[getindex].tabScanIn[i].date.split(' ');
            var d1=d0[0].split('-');
            var d1=d1[2]+d1[1]+d1[0];
            var d2=d0[1].replace(/:/g,'');
            var d3=parseFloat(d1+d2);
            glist[getindex].tabScanIn[i]['dateSort']=d3;
            glist[getindex].tabScanIn[i]['type']='in';
            cumTabScan.push(glist[getindex].tabScanIn[i]);
          };
          for(var i=0; i<glist[getindex].tabScanOut.length;i++){
            var d0=glist[getindex].tabScanOut[i].date.split(' ');
            var d1=d0[0].split('-');
            var d1=d1[2]+d1[1]+d1[0];
            var d2=d0[1].replace(/:/g,'');
            var d3=parseFloat(d1+d2);
            glist[getindex].tabScanOut[i]['dateSort']=d3;
            glist[getindex].tabScanOut[i]['type']='out';
            cumTabScan.push(glist[getindex].tabScanOut[i]);
          };
          cumTabScan=cumTabScan.sort(function(a,b){return parseInt(b.dateSort)-parseInt(a.dateSort)});
          if(cumTabScan.length>0){
            $('.conttranspar').html('');
            var indexin=glist[getindex].totScanIn;
            var indexout=glist[getindex].totScanOut;
            for(var i=0; i<cumTabScan.length;i++){
              var cltop='';
              if(i%2==0){
                cltop='litranspar ltpg';
              }else{
                cltop='litranspar';
              };
              var indexscan=0;
              var nametypescan='';
              var type='';
              if(cumTabScan[i].type=='in'){
                indexin--;
                indexscan=indexin;
                cltop+=' ltpin'
                type='in';
                nametypescan='<i class="fa-solid fa-arrow-up-to-line" style="transform:rotate(90deg)"></i> &nbsp;Entrée';
              }else if(cumTabScan[i].type=='out'){
                indexout--;
                indexscan=indexout;
                cltop+=' ltpout'
                type='out';
                nametypescan='<i class="fa-solid fa-arrow-up-from-line" style="transform:rotate(-90deg)"></i> &nbsp;Sortie';
              };
              var nameaccess='';
              if(cumTabScan[i].idStaff=='fromBo'){
                nameaccess='Backoffice';
              }else{
                for(var u=0;u<dataKeys.length;u++){
                  if(cumTabScan[i].idStaff==dataKeys[u]._id){
                    nameaccess=dataKeys[u].name;
                    break;
                  };
                };
              };
              $('.conttranspar').append('<div class="'+cltop+'" type="'+type+'" index="'+indexscan+'"><div class="typscan">'+nametypescan+'</div><div class="accesscan">'+nameaccess+'</div><div class="datscan">'+cumTabScan[i].date+'</div><div class="actscan"><i class="fa-solid fa-trash-can removescan"></i><span class="cancelrem">Annuler</span></div><div class="confrem">Supprimer</div></div>');
            };
            if(unlockremove>=5){
              $('.actscan').css({'display':'block'});
            };
          }else{
            $('.conttranspar').html('<div class="messdettrans">Aucun contrôle n\'a encore été effectué</div>');
          };
          if(glist[getindex].flag=='true'){
            $('.btnflagpar').addClass('btnactrue').html('Retirer marque<span class="cutresp5"> &nbsp;<i class="fas fa-flag"></i></span>');
          }else{
            $('.btnflagpar').removeClass('btnactrue').html('Marquer<span class="cutresp5"> &nbsp;<i class="far fa-flag"></i></span>');
          };
          if(glist[getindex].ban=='true'){
            $('.btnbanpar').addClass('btnactrue').html('Débloquer<span class="cutresp5"> &nbsp;<i class="fas fa-hand-paper"></i></span>');
          }else{
            $('.btnbanpar').removeClass('btnactrue').html('Bloquer<span class="cutresp5"> &nbsp;<i class="far fa-hand-paper"></i></span>');
          };
          $('.fdpopedit').fadeIn(500);
          $('.bloceditLP').css({'opacity':'1.0','right':'0px'});
        });
      };
      $('.infonfcpar').click(function(){
        var thi=$(this);
        if(thi.attr('class')=='infonfcpar'){
          thi.html('<i class="fa-regular fa-circle-xmark"></i>');
          thi.addClass('infonfcparon');
          $('.liinfonfcmore').css({'height':'140px','opacity':1});
        }else{
          thi.html('<i class="fa-regular fa-circle-info"></i>');
          thi.removeClass('infonfcparon');
          $('.liinfonfcmore').css({'height':'1px','opacity':0});
        };
      });
      var unlockremove=0;
      $('.fa-history').click(function(){
        unlockremove++;
        if(unlockremove>=5){
          $('.actscan').css({'display':'block'});
        };
      });
      $('.conttranspar').on('click','.removescan',function(){
        var lit=$(this).parent().parent();
        $('.litranspar').removeClass('litransparOn');
        $('.confrem').css({'width':'0%','margin-left':'0%'});
        lit.addClass('litransparOn');
        lit.children('.confrem').css({'width':'24%','margin-left':'-24%'});
        setTimeout(function(){
          $('.actscan').removeClass('actscanOn');
          lit.children('.actscan').addClass('actscanOn');
        },100);
      });
      $('.conttranspar').on('click','.cancelrem',function(){
        var lit=$(this).parent().parent();
        $('.litranspar').removeClass('litransparOn');
        $('.confrem').css({'width':'0%','margin-left':'0%'});
        setTimeout(function(){
          $('.actscan').removeClass('actscanOn');
        },100);
      });
      $('.conttranspar').on('click','.confrem',function(){
        var valtotscan=glist[getindex].totScan;
        var valmaxscan=glist[getindex].maxscan;
        var lit=$(this).parent();
        var type = lit.attr('type');
        var indexscan = lit.attr('index');
        var tcs=$('.mtsoldepar');
        if( type=='in' && (valtotscan-1)<0 ){
          tcs.css({'transform':'rotate(25deg)'});
          setTimeout(function(){tcs.css({'transform':'rotate(-15deg)'});},150);
          setTimeout(function(){tcs.css({'transform':'rotate(10deg)'});},300);
          setTimeout(function(){tcs.css({'transform':'rotate(-5deg)'});},450);
          setTimeout(function(){tcs.css({'transform':'rotate(0deg)'});},600);
        }else if( type=='out' && (valtotscan+1)>valmaxscan ){
          tcs.css({'transform':'rotate(25deg)'});
          setTimeout(function(){tcs.css({'transform':'rotate(-15deg)'});},150);
          setTimeout(function(){tcs.css({'transform':'rotate(10deg)'});},300);
          setTimeout(function(){tcs.css({'transform':'rotate(-5deg)'});},450);
          setTimeout(function(){tcs.css({'transform':'rotate(0deg)'});},600);
        }else{
          dataCustomer['scans']=Object.assign({},glist[getindex].scans);
          if(type=='in'){
            glist[getindex].totScan--;
            glist[getindex].totScanIn--;
            if(dataCustomer['scans'][idlist].nbrScan>1){
              dataCustomer['scans'][idlist].nbrScan--;
              dataCustomer['scans'][idlist].dateScan.splice(indexscan,1);
            }else{
              delete dataCustomer['scans'][idlist];
            };
            glist[getindex].scans=dataCustomer['scans'];
            glist[getindex].tabScanIn.splice(indexscan,1);
            t.boService.editParticipant(getpar,dataCustomer);
          }else if(type=='out'){
            glist[getindex].totScan++;
            glist[getindex].totScanOut--;
            if(dataCustomer['scans'][idlist].nbrDeScan>1){
              dataCustomer['scans'][idlist].nbrDeScan--;
              dataCustomer['scans'][idlist].dateDescan.splice(indexscan,1);
            }else if(dataCustomer['scans'][idlist].nbrDeScan==1){
              delete dataCustomer['scans'][idlist].nbrDeScan;
              delete dataCustomer['scans'][idlist].dateDescan;
            };
            glist[getindex].scans=dataCustomer['scans'];
            glist[getindex].tabScanOut.splice(indexscan,1);
            t.boService.editParticipant(getpar,dataCustomer);
          };
          var newtotscan=glist[getindex].totScan;
          var cs='cs0';
          if(newtotscan>0){
            cs='cs1';
            if(newtotscan>=valmaxscan){
              cs='cs2';
            };
          };
          $('#'+getpar+'').find('.circlescan').attr('class','circlescan '+cs+'');
          $('#'+getpar+'').find('.nbscan').html(newtotscan);
          $('.detvalscan').html(newtotscan);
          lit.removeClass('litransparOn').css({'height':'0px','border-color':'#e9e9e9','box-shadow':'inset 0px 2px 20px -1px rgb(0,0,0,0.18)'});
          lit.children('.confrem').css({'width':'0%','margin-left':'0%'});
          setTimeout(function(){
            lit.remove();
            if(type=='in'){
              var newindex=glist[getindex].totScanIn;
              $('.ltpin').each(function(){
                $(this).attr('index',newindex);
                newindex--;
              });
            }else if(type=='out'){
              var newindex=glist[getindex].totScanOut;
              $('.ltpout').each(function(){
                $(this).attr('index',newindex);
                newindex--;
              });
            };
          },299);
        };
      });
      $('.fdpopedit,.btnclosepoppar').click(function(){
        $('.btnsavepar').css({'margin-right':'-220px','pointer-events':'none'});
        $('.accountnum').css({'opacity':'1'});
        $('.informmail').removeClass('signalinvide');
        invalidmail=0;
        $('.fdpopedit').fadeOut(500);
        if(wiw>830){
          $('.bloceditLP').css({'opacity':'0.0','right':'-570px'});
        }else{
          $('.bloceditLP').css({'opacity':'0.0','right':'-100%'});
        };
        setTimeout(function(){
          $('.bloceditLP').animate({scrollTop:0},0);
        },600);
      });
      var checlic2=0;
      $('.btnflagpar').click(function(){
        if(checlic2==0){
          checlic2=1;
          $('.savestick').css({'right':'0px'});
          $(this).css({'opacity':0.6});
          if($(this).attr('class')=='btnac btnflagpar btnactrue'){
            dataCustomer['flag']='false';
            glist[getindex].flag='false';
            $(this).removeClass('btnactrue').html('Marquer<span class="cutresp5"> &nbsp;<i class="far fa-flag"></i></span>');
          }else{
            dataCustomer['flag']='true';
            glist[getindex].flag='true';
            $(this).addClass('btnactrue').html('Retirer marque<span class="cutresp5"> &nbsp;<i class="fas fa-flag"></i></span>');
          };
          if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          t.boService.editParticipant(getpar,dataCustomer).then(data => {
            $('.savestick').css({'right':'-260px'});
            $(this).css({'opacity':1.0});
            dataCustomer={};
            checlic2=0;
          }).catch(error => {
            $('.savestick').css({'right':'-260px'});
            $(this).css({'opacity':1.0});
            checlic2=0;
          });
        };
      });
      var checlic3=0;
      $('.btnbanpar').click(function(){
        if(checlic3==0){
          checlic3=1;
          $('.savestick').css({'right':'0px'});
          $(this).css({'opacity':0.6});
          if($(this).attr('class')=='btnac btnbanpar btnactrue'){
            dataCustomer['ban']='false';
            glist[getindex].ban='false';
            $(this).removeClass('btnactrue').html('Bloquer<span class="cutresp5"> &nbsp;<i class="far fa-hand-paper"></i></span>');
          }else{
            dataCustomer['ban']='true';
            glist[getindex].ban='true';
            $(this).addClass('btnactrue').html('Débloquer<span class="cutresp5"> &nbsp;<i class="fas fa-hand-paper"></i></span>');
          };
          if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
          /* t.boService.editCustomer(getpar,dataCustomer).then(data => {
            $('.savestick').css({'right':'-260px'});
            $(this).css({'opacity':1.0});
            dataCustomer={};
            checlic3=0;
          }).catch(error => {
            $('.savestick').css({'right':'-260px'});
            $(this).css({'opacity':1.0});
            checlic3=0;
          }); */
        };
      });
      $('.informpar').keyup(function(){
        $('.btnsavepar').css({'margin-right':'60px','pointer-events':'visible'});
        $('.accountnum').css({'opacity':'0'});
      });
      var re=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var invalidmail=0;
      $('.informmail').change(function(){
        if(!($('.informmail').val()).match(re)){
          $('.informmail').addClass('signalinvide');
          invalidmail=1;
        }else{
          $('.informmail').removeClass('signalinvide');
          invalidmail=0;
        };
      });
      var checlic8=0;
      $('.btnsavepar').click(function(){
        if(invalidmail==0){
          var vinplastname=$('.informlastname').val();
          var vinpfirstname=$('.informfirstname').val();
          if(checlic8==0){
            checlic8=1;
            $('.btnsavepar').html('Sauvegarde en cours...');
            dataCustomer['lastNameUser']=vinplastname;
            dataCustomer['firstNameUser']=vinpfirstname;
            t.boService.editParticipant(getpar,dataCustomer).then(data => {
              glist[getindex].lastNameUser=vinplastname;
              glist[getindex].firstNameUser=vinpfirstname;
              glist[getindex].firstAndLastName=vinplastname.substr(0,1).toUpperCase()+vinplastname.substr(1).toLowerCase()+' '+vinpfirstname.substr(0,1).toUpperCase()+vinpfirstname.substr(1).toLowerCase();
              if(dataSearch.length>0){searchLP();}else{loadLP(glist)};
              $('.btnsavepar').html(' <i class="fas fa-save"></i> &nbsp; Sauvegarder ').css({'margin-right':'-220px','pointer-events':'none'});
              $('.accountnum').css({'opacity':'1'});
              dataCustomer={};
              checlic8=0;
            }).catch(error => {
              $('.btnsavepar').html(' <i class="fas fa-save"></i> &nbsp; Sauvegarder ');
              dataCustomer={};
              checlic8=0;
            });
          };
        };
      });
      // if(darkMode=='on'){t.boService.darkMode('on')}else{t.boService.darkMode('off')};
      // ---------------------------------------------------------
    });
  };

}
